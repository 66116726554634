import {
    addPropertyControls,
    ControlType,
    RenderTarget,
    // @ts-ignore
    useQueryData,
} from "framer"
import { useEffect, useRef, useState } from "react"

function formatNumber(value, commas) {
    let numberString = value.toFixed(0)
    if (commas) {
        numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return numberString
}

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function CMSCounter(props) {
    const { transform } = props
    const cl = props.collectionList?.[0]
    const isCanvas = RenderTarget.current() === RenderTarget.canvas
    const clProps = cl?.props?.children?.props

    let items = props.placeholder

    // Only display number of CMS items in the preview and live website.
    if (clProps?.query && !isCanvas) {
        items = clProps.children(useQueryData(clProps.query)).length
    }

    let prefix = ""
    let suffix = ""
    if (transform) {
        if (transform.plural) {
            prefix =
                items == 1 ? transform.singularPrefix : transform.pluralPrefix
            suffix =
                items == 1 ? transform.singularSuffix : transform.pluralSuffix
        } else {
            prefix = transform.prefix
            suffix = transform.suffix
        }
    }

    console.log("props.style", props.style)

    return (
        <div
            style={{
                color: props.fontColor,
                userSelect: props.textSelect ? "auto" : "none",
                margin: 0,
                whiteSpace: props.style?.width ? undefined : "nowrap",
                ...props.font,
                ...props.style,
            }}
        >
            {prefix}
            {formatNumber(items, props.commas)}
            {suffix}
        </div>
    )
}

CMSCounter.displayName = "CMS Item Counter"

addPropertyControls(CMSCounter, {
    collectionList: {
        type: ControlType.ComponentInstance,
        description:
            "The number of CMS items is only shown in the preview and live website.",
    },
    placeholder: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        description: "Placeholder number is shown in the editor.",
    },
    commas: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#000",
    },
    font: {
        type: "font",
        controls: "extended",
    },
    transform: {
        type: ControlType.Object,
        optional: true,
        controls: {
            plural: {
                type: ControlType.Boolean,
                defaultValue: false,
                enabledTitle: "On",
                disabledTitle: "Off",
            },
            prefix: {
                type: ControlType.String,
                hidden(props) {
                    return props.plural
                },
            },
            suffix: {
                type: ControlType.String,
                hidden(props) {
                    return props.plural
                },
            },
            singularPrefix: {
                type: ControlType.String,
                hidden(props) {
                    return !props.plural
                },
            },
            singularSuffix: {
                type: ControlType.String,
                hidden(props) {
                    return !props.plural
                },
            },
            pluralPrefix: {
                type: ControlType.String,
                hidden(props) {
                    return !props.plural
                },
            },
            pluralSuffix: {
                type: ControlType.String,
                hidden(props) {
                    return !props.plural
                },
            },
        },
    },
    textSelect: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
})
